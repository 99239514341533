
/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3.5rem;
  --header-height-mobile: 4rem;
  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  /* --black-color: hsl(220, 24%, 12%);
  --black-color-light: hsl(220, 24%, 15%);
  --black-color-lighten: hsl(220, 20%, 18%);
  --white-color: #fff;
  --body-color: hsl(220, 100%, 97%); */

/* 
  wersja biała */
  --black-color:  #fff;
  /* --black-color-light: hsl(220, 24%, 15%);
  --black-color-lighten: hsl(220, 20%, 18%); */
  --white-color: #000;
  --body-color: hsl(220, 100%, 97%);
  --black-color-light: hsl(220, 100%, 97%);
  --black-color-lighten: hsl(220, 100%, 97%);

   
    --primary: #000;
    --btn-hover: #2a2a2a;
    --white: #fff;
    --nav-hover: #666;
 

  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  /* --body-font: "Montserrat", sans-serif; */
  /* --normal-font-size: .938rem; */

  /*========== Font weight ==========*/
  --font-regular: 400;
  --font-semi-bold: 600;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

/*========== Responsive typography ==========*/
@media screen and (min-width: 1024px) {
  :root {
    --normal-font-size: 1rem;
  }
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  /* font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color); */
}

ul {
  list-style: none;
  /* Color highlighting when pressed on mobile devices */
  /*-webkit-tap-highlight-color: transparent;*/
}

a {
  text-decoration: none;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.containerMENU {
  /* max-width: 1120px; */
  /* margin-inline: 1.5rem; */
  /* padding: 0 2rem; */
}

@media screen and (max-width: 767px) { 
.containerMENU   {
    width: 100%;
    padding-top: 4px;
    padding-bottom: 4px;
}}


.navigation3 {
  z-index: 999;
  /* background-color: transparent; */
  width: 100%;
  position: fixed;
}
@media screen and (max-width: 991px) {
.navigation3 {
    z-index: 9999;
    /* background-color: whitesmoke; */
    mix-blend-mode: normal;
    position: fixed;
} }




/*=============== HEADER ===============*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* background-color: var(--black-color); */
  /* box-shadow: 0 2px 16px hsla(220, 32%, 8%, .3); */
  z-index: var(--z-fixed);
}

/*=============== NAV ===============*/
/* .nav {
  height: var(--header-height);
} */

.nav__logo, 
.nav__burger, 
.nav__close {
  /* color: var(--white-color); */
}

.nav__data {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__logo {
  display: inline-flex;
  align-items: center;
  column-gap: .25rem;
  font-weight: var(--font-semi-bold);
  /* Color highlighting when pressed on mobile devices */
  /*-webkit-tap-highlight-color: transparent;*/
}

.nav__logo i {
  font-weight: initial;
  font-size: 1.25rem;
}

.nav__toggle {
  position: relative;
  width: 32px;
  height: 32px;
}

.nav__burger, 
.nav__close {
  position: absolute;
  width: max-content;
  height: max-content;
  inset: 0;
  margin: auto;
  font-size: 1.75rem;
  cursor: pointer;
  transition: opacity .1s, transform .4s;
}

.nav__close {
  opacity: 0;
}

/* Navigation for mobile devices */
@media screen and (max-width: 1117px) {
  .nav__menu {
    position: absolute;
    left: 0;
    top: 2.5rem;
    width: 100%;
    height: calc(100vh - 3.5rem);
    overflow: auto;
    pointer-events: none;
    opacity: 0;
    transition: top .4s, opacity .3s;
    background-color: #fafafa;
  }

  .nav {
    height: var(--header-height-mobile);
}


  .nav__menu::-webkit-scrollbar {
    width: 0;
  }
  .nav__list {
    /* background-color: var(--black-color); */
    padding-top: 1rem;
    /* height: 100vh; */
  }


}

.nav__link {
  /* font-size: 16px; */
  /* color: var(--white-color);
  background-color: var(--black-color);
  font-weight: var(--font-semi-bold); */
  padding: 1.25rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color .3s;
  /* text-transform: uppercase; */
}

  .nav__link:hover {
  /* background-color: var(--black-color-light); */
}  

.nav li a:hover {
  color: var(--nav-hover);
}


.activenavlink {
  color: var(--white-color)
}



/* Show menu */
.show-menu {
  opacity: 1;
  top: 3.5rem;
  pointer-events: initial;
  transform: translateY(0px);
    transition: transform 800ms ease 0s;
}

/* Show icon */
.show-icon .nav__burger {
  opacity: 0;
  transform: rotate(90deg);
}
.show-icon .nav__close {
  opacity: 1;
  transform: rotate(90deg);
}

/*=============== DROPDOWN ===============*/
.dropdown__item {
  cursor: pointer;
}

.dropdown__arrow {
  font-size: 1.25rem;
  font-weight: initial;
  transition: transform .4s;
}

.dropdown__link, 
.dropdown__sublink {

  font-size: 1rem;
  font-weight: 400;
  color: var(--primary);
  transition: all .2s ease-in;


  padding: 1.25rem 1.25rem 1.25rem 2.5rem;
  /* color: var(--white-color); */
  background-color: var(--black-color);
  display: flex;
  align-items: center;
  column-gap: .5rem;
  /* font-weight: var(--font-semi-bold); */
  transition: background-color .3s;
}

.dropdown__link i, 
.dropdown__sublink i {
  /* font-size: 1.25rem; */
  font-weight: initial;
}

.dropdown__link:hover, 
.dropdown__sublink:hover {
  background-color: var(--black-color);
}

.dropdown__menu, 
.dropdown__submenu {
  max-height: 0;
  overflow: hidden;
  transition: max-height .4s ease-out;
}

/* Show dropdown menu & submenu */
.dropdown__item:hover .dropdown__menu, 
.dropdown__subitem:hover > .dropdown__submenu {
  max-height: 1000px;
  transition: max-height .4s ease-in;
}

/* Rotate dropdown icon */
.dropdown__item:hover .dropdown__arrow {
  transform: rotate(180deg);
}

/*=============== DROPDOWN SUBMENU ===============*/
.dropdown__add {
  margin-left: auto;
}

.dropdown__sublink {
  background-color: var(--black-color);
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 340px) {
  .containerMENU {
    margin-inline: 1rem;
  }

  .nav__link {
    padding-inline: 1rem;
  }
}

/* For large devices */
@media screen and (min-width: 1118px) {
  .containerMENU {
    /* margin-inline: auto; */
  }

  .nav {
    height: calc(var(--header-height) + 1rem);
    display: flex;
    justify-content: space-between;
  }
  .nav__toggle {
    display: none;
  }
  .nav__list {
    margin: 0 1rem;
    height: 100%;
    display: flex;
     column-gap: 3rem;  
  }
  .nav__link {
    height: 100%;
    padding: 0;
    justify-content: initial;
    /* column-gap: .25rem; */
    letter-spacing: -.03em;
    /* font-size: 1rem; */
    /* font-size: 15px; */
    font-weight: 400;
    color: var(--primary);
    transition: all .2s ease-in;
  }
  /* .nav__link {
    height: 100%;
    padding: 0;
    justify-content: initial;
    column-gap: .25rem;
} */
 
  .nav__link:hover {
    background-color: transparent;
  }

  .dropdown__item, 
  .dropdown__subitem {
    position: relative;
  }

  .dropdown__menu, 
  .dropdown__submenu {
    max-height: initial;
    overflow: initial;
    position: absolute;
    left: 0;
    top: 6rem;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s, top .3s;
  }

  .dropdown__link, 
  .dropdown__sublink {
    padding-inline: 1rem 3.5rem;
  }

  .dropdown__subitem .dropdown__link {
    padding-inline: 1rem;
  }

  .dropdown__submenu {
    position: absolute;
    left: 100%;
    top: .5rem;
  }

  /* Show dropdown menu */
  .dropdown__item:hover .dropdown__menu {
    opacity: 1;
    /* top: 5.5rem; */
    top: 3.35rem;
    pointer-events: initial;
    transition: top .3s;
  }

  /* Show dropdown submenu */
  .dropdown__subitem:hover > .dropdown__submenu {
    opacity: 1;
    top: 0;
    pointer-events: initial;
    transition: top .3s;
  }
}


@media (min-width: 1536px) {
  .container-menu {
    max-width: 1536px;
  }}
  
  @media (min-width: 1280px){
  .container-menu {
    max-width: 1280px;
  }}
  
  @media (min-width: 1024px){
  .container-menu {
    max-width: 1024px;
  }}
  
  @media (min-width: 768px){
  .container-menu {
    max-width: 768px;
  }}
  
  @media (min-width: 640px){
  .container-menu {
    max-width: 640px;
  }}

/* kontakt button */



@media (min-width: 768px) { 
.md\:order-2 {
    order: 2;
}

.md\:inline-block {
  display: inline-block;
}}
.items-center {
    align-items: center;
}
.flex {
    display: flex;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.contact .btn-primary {
    background-color: var(--primary);
    color: var(--white);
    border: 1px solid var(--primary);
}
.btn-outline-primary, .btn-primary, .btn-secondary {
    border-radius: 2rem;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 500;
    transition: all .1s ease-in;
}
.hidden {
    display: none;
}
 

@media (max-width: 1024px) {
/* .lg\:w-auto {
    width: auto;
} */
.lg\:flex {
  display: flex;
}

.lg\:order-1 {
  order: 1;
}

}
 
.nav-sec {

   -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    background-color: rgba(255,255,255,.5);

  /* -webkit-backdrop-filter: blur(10px); */
  /* backdrop-filter: blur(10px); */
  /* background-color: hsla(0, 0%, 100%, .9); */
  /* -webkit-backdrop-filter: saturate(170%) blur(40px);
  backdrop-filter: saturate(170%) blur(40px);
   background-image: linear-gradient(rgba(255, 255, 255, .85), rgba(255, 255, 255, .85));   */
   /* backdrop-filter: blur(12px);
   background-color: rgba(255, 255, 255, .5); */

}

.nav li {
  /* margin-right: 2rem; */
}
 
.justify-between {
    justify-content: space-between;
}
 
.w-full {
    width: 100%;
}

.flex-wrap {
  flex-wrap: wrap;
}
 
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.container-menu {
  width: 100%;
}



.floating-item {
 
 
 
}

@media screen and (max-width: 1117px) {
  .floating-item {
    text-align: center;
      padding: 12px 20px;
      font-size: 20px;
  }


  .menu-links-wrapper {
    grid-row-gap: 8px;
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
    /* background-color: transparent; */
    flex-direction: column;
    align-items: center;
    display: flex;
 padding: 40px 24px 40px;
 transform: scale(1);
 transition: all 500ms ease-in;
}

.social-nav_container,
.nav-link_social   {


display: none;   
} 

.nav-sec {background-color: #fafafa;
}

}
