@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap";
:root {
  --header-height: 3.5rem;
  --header-height-mobile: 4rem;
  --black-color: #fff;
  --white-color: #000;
  --body-color: #f0f5ff;
  --black-color-light: #f0f5ff;
  --black-color-lighten: #f0f5ff;
  --primary: #000;
  --btn-hover: #2a2a2a;
  --white: #fff;
  --nav-hover: #666;
  --font-regular: 400;
  --font-semi-bold: 600;
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 1024px) {
  :root {
    --normal-font-size: 1rem;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .containerMENU {
    width: 100%;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.navigation3 {
  z-index: 999;
  width: 100%;
  position: fixed;
}

@media screen and (max-width: 991px) {
  .navigation3 {
    z-index: 9999;
    mix-blend-mode: normal;
    position: fixed;
  }
}

.header {
  z-index: var(--z-fixed);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.nav__data {
  justify-content: space-between;
  align-items: center;
  height: 100%;
  display: flex;
}

.nav__logo {
  font-weight: var(--font-semi-bold);
  align-items: center;
  column-gap: .25rem;
  display: inline-flex;
}

.nav__logo i {
  font-weight: initial;
  font-size: 1.25rem;
}

.nav__toggle {
  width: 32px;
  height: 32px;
  position: relative;
}

.nav__burger, .nav__close {
  cursor: pointer;
  width: max-content;
  height: max-content;
  margin: auto;
  font-size: 1.75rem;
  transition: opacity .1s, transform .4s;
  position: absolute;
  inset: 0;
}

.nav__close {
  opacity: 0;
}

@media screen and (max-width: 1117px) {
  .nav__menu {
    pointer-events: none;
    opacity: 0;
    background-color: #fafafa;
    width: 100%;
    height: calc(100vh - 3.5rem);
    transition: top .4s, opacity .3s;
    position: absolute;
    top: 2.5rem;
    left: 0;
    overflow: auto;
  }

  .nav {
    height: var(--header-height-mobile);
  }

  .nav__menu::-webkit-scrollbar {
    width: 0;
  }

  .nav__list {
    padding-top: 1rem;
  }
}

.nav__link {
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1.5rem;
  transition: background-color .3s;
  display: flex;
}

.nav li a:hover {
  color: var(--nav-hover);
}

.activenavlink {
  color: var(--white-color);
}

.show-menu {
  opacity: 1;
  pointer-events: initial;
  transition: transform .8s;
  top: 3.5rem;
  transform: translateY(0);
}

.show-icon .nav__burger {
  opacity: 0;
  transform: rotate(90deg);
}

.show-icon .nav__close {
  opacity: 1;
  transform: rotate(90deg);
}

.dropdown__item {
  cursor: pointer;
}

.dropdown__arrow {
  font-size: 1.25rem;
  font-weight: initial;
  transition: transform .4s;
}

.dropdown__link, .dropdown__sublink {
  color: var(--primary);
  background-color: var(--black-color);
  align-items: center;
  column-gap: .5rem;
  padding: 1.25rem 1.25rem 1.25rem 2.5rem;
  font-size: 1rem;
  font-weight: 400;
  transition: background-color .3s;
  display: flex;
}

.dropdown__link i, .dropdown__sublink i {
  font-weight: initial;
}

.dropdown__link:hover, .dropdown__sublink:hover {
  background-color: var(--black-color);
}

.dropdown__menu, .dropdown__submenu {
  max-height: 0;
  transition: max-height .4s ease-out;
  overflow: hidden;
}

.dropdown__item:hover .dropdown__menu, .dropdown__subitem:hover > .dropdown__submenu {
  max-height: 1000px;
  transition: max-height .4s ease-in;
}

.dropdown__item:hover .dropdown__arrow {
  transform: rotate(180deg);
}

.dropdown__add {
  margin-left: auto;
}

.dropdown__sublink {
  background-color: var(--black-color);
}

@media screen and (max-width: 340px) {
  .containerMENU {
    margin-inline: 1rem;
  }

  .nav__link {
    padding-inline: 1rem;
  }
}

@media screen and (min-width: 1118px) {
  .nav {
    height: calc(var(--header-height)  + 1rem);
    justify-content: space-between;
    display: flex;
  }

  .nav__toggle {
    display: none;
  }

  .nav__list {
    column-gap: 3rem;
    height: 100%;
    margin: 0 1rem;
    display: flex;
  }

  .nav__link {
    justify-content: initial;
    letter-spacing: -.03em;
    color: var(--primary);
    height: 100%;
    padding: 0;
    font-weight: 400;
    transition: all .2s ease-in;
  }

  .nav__link:hover {
    background-color: #0000;
  }

  .dropdown__item, .dropdown__subitem {
    position: relative;
  }

  .dropdown__menu, .dropdown__submenu {
    max-height: initial;
    overflow: initial;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s, top .3s;
    position: absolute;
    top: 6rem;
    left: 0;
  }

  .dropdown__link, .dropdown__sublink {
    padding-inline: 1rem 3.5rem;
  }

  .dropdown__subitem .dropdown__link {
    padding-inline: 1rem;
  }

  .dropdown__submenu {
    position: absolute;
    top: .5rem;
    left: 100%;
  }

  .dropdown__item:hover .dropdown__menu {
    opacity: 1;
    pointer-events: initial;
    transition: top .3s;
    top: 3.35rem;
  }

  .dropdown__subitem:hover > .dropdown__submenu {
    opacity: 1;
    pointer-events: initial;
    transition: top .3s;
    top: 0;
  }
}

@media (min-width: 1536px) {
  .container-menu {
    max-width: 1536px;
  }
}

@media (min-width: 1280px) {
  .container-menu {
    max-width: 1280px;
  }
}

@media (min-width: 1024px) {
  .container-menu {
    max-width: 1024px;
  }
}

@media (min-width: 768px) {
  .container-menu {
    max-width: 768px;
  }
}

@media (min-width: 640px) {
  .container-menu {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .md\:order-2 {
    order: 2;
  }

  .md\:inline-block {
    display: inline-block;
  }
}

.items-center {
  align-items: center;
}

.flex {
  display: flex;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.contact .btn-primary {
  background-color: var(--primary);
  color: var(--white);
  border: 1px solid var(--primary);
}

.btn-outline-primary, .btn-primary, .btn-secondary {
  border-radius: 2rem;
  padding: .75rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  transition: all .1s ease-in;
}

.hidden {
  display: none;
}

@media (max-width: 1024px) {
  .lg\:flex {
    display: flex;
  }

  .lg\:order-1 {
    order: 1;
  }
}

.nav-sec {
  backdrop-filter: blur(12px);
  background-color: #ffffff80;
}

.justify-between {
  justify-content: space-between;
}

.w-full {
  width: 100%;
}

.flex-wrap {
  flex-wrap: wrap;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.container-menu {
  width: 100%;
}

@media screen and (max-width: 1117px) {
  .floating-item {
    text-align: center;
    padding: 12px 20px;
    font-size: 20px;
  }

  .menu-links-wrapper {
    grid-row-gap: 8px;
    backdrop-filter: none;
    flex-direction: column;
    align-items: center;
    padding: 40px 24px;
    transition: all .5s ease-in;
    display: flex;
    transform: scale(1);
  }

  .social-nav_container, .nav-link_social {
    display: none;
  }

  .nav-sec {
    background-color: #fafafa;
  }
}

/*# sourceMappingURL=index.035358f8.css.map */
